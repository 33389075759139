import React, { useEffect, useState } from "react";
import "./Homepage.css";
import "react-toastify/dist/ReactToastify.css";
import { Link, NavLink } from "react-router-dom";
import { Layout, Menu, Progress, Space, Tooltip, theme } from "antd";
import { useNavigate } from "react-router-dom";
import Navbar from "../pages/Admin/Navbar";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Box,
} from "@chakra-ui/react";
import { useAuth } from "./contexts/contextProvider";
import { Card, Col, Row, Statistic } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { BsReverseLayoutSidebarInsetReverse } from "react-icons/bs";
const userData = JSON.parse(localStorage.getItem("admin")) || {};

const Admin = userData?.admin;
const { Header, Sider, Content } = Layout;

function Homepage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const { auth } = useAuth();

  return (
    <>
      <Navbar style={{ position: "fixed", background: "white" }} />
      <Button
        ref={btnRef}
        colorScheme="teal"
        onClick={onOpen}
        marginLeft={4}
        marginTop={2}
      >
        <BsReverseLayoutSidebarInsetReverse />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Admin Dashboard</DrawerHeader>
          <DrawerBody>
            <Link to="/dashboard">
              <Button width="100%" marginBottom={2} colorScheme="green">
                Dashboard
              </Button>
            </Link>
            <Link to="/createCategory">
              <Button width="100%" marginBottom={2} colorScheme="green">
                All Books Category
              </Button>
            </Link>
            <Link to="/createProduct">
              <Button width="100%" marginBottom={2} colorScheme="green">
                Create Book
              </Button>
            </Link>

            <Link to="/products">
              <Button width="100%" marginBottom={2} colorScheme="green">
                Books
              </Button>
            </Link>

            <Link to="/adminOrders">
              <Button width="100%" marginBottom={2} colorScheme="green">
                My Books Orders
              </Button>
            </Link>

            <Link to="/slider">
              <Button width="100%" marginBottom={2} colorScheme="green">
                Slider
              </Button>
            </Link>

            <Link to="/multisliders">
              <Button width="100%" marginBottom={2} colorScheme="green">
                Multi Sliders
              </Button>
            </Link>

            <Link to="/users">
              <Button width="100%" marginBottom={2} colorScheme="green">
                All Users
              </Button>
            </Link>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Box width="100%">
        <div className="row" style={{ marginTop: "10px", width: "100%" }}>
          <div className="col-md-6">
            <div className="card p-3 admin_data" style={{ width: "100%" }}>
              <h1 className="text-center" style={{ background: "whitesmoke" }}>
                Admin Profile
              </h1>
              <img className="admin_profile" src="images.jpg" alt="" />
              <h6 className="data">Admin Name: {Admin.name}</h6>
              <h6 className="data">Admin Email: {Admin.email}</h6>
              <h6 className="data">Admin Contact: {Admin?.mobile}</h6>

              <div className="col-md-12 mt-3">
                <div className="card p-2 text-center" style={{ width: "100%" }}>
                  <h6>Total Users</h6>
                  {/* Display total users value here */}
                  <Progress percent={30} />
                  <Progress percent={50} status="active" />
                  <Progress percent={70} status="exception" />
                  <Progress percent={100} />
                  <Progress percent={50} showInfo={false} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card p-3" style={{ width: "100%" }}>
              <h1 className="text-center" style={{ background: "whitesmoke" }}>
                Total Account
              </h1>
              {/* Content for the second card in the first pair */}
              <div className="col-md-6" style={{ width: "100%" }}>
                <div className="card p-3" style={{ width: "100%" }}>
                  {/* Small Cards for E-commerce Metrics */}
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="card p-2" style={{ width: "100%" }}>
                        <h6 style={{ width: "100%" }}>Total Sales</h6>
                        {/* Display total sales value here */}
                        <Row gutter={16}>
                          <Col span={12}>
                            <Card bordered={false}>
                              <Statistic
                                title="Active"
                                value={11.28}
                                precision={2}
                                valueStyle={{ color: "#3f8600" }}
                                prefix={<ArrowUpOutlined />}
                                suffix="%"
                              />
                            </Card>
                          </Col>
                          <Col span={12}>
                            <Card bordered={false}>
                              <Statistic
                                title="Idle"
                                value={9.3}
                                precision={2}
                                valueStyle={{ color: "#cf1322" }}
                                prefix={<ArrowDownOutlined />}
                                suffix="%"
                              />
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="card p-2 text-center"
                        style={{ width: "100%" }}
                      >
                        <h6>Total Income</h6>
                        {/* Display total income value here */}
                        <Row gutter={16}>
                          <Col span={12} style={{ height: 193 }}>
                            <Statistic
                              title="Account Balance"
                              value={112893}
                              precision={2}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div
                        className="card p-2 text-center"
                        style={{ width: "100%" }}
                      >
                        <h6>Total Orders</h6>
                        <Tooltip title="3 done / 3 in progress / 4 to do">
                          <Progress percent={60} success={{ percent: 30 }} />
                        </Tooltip>
                        <Space wrap>
                          <Tooltip title="3 done / 3 in progress / 4 to do">
                            <Progress
                              percent={60}
                              success={{ percent: 30 }}
                              type="circle"
                              style={{ padding: "1px" }}
                            />
                            <Progress
                              percent={80}
                              success={{ percent: 30 }}
                              type="circle"
                              style={{ padding: "1px" }}
                            />
                            <Progress
                              percent={50}
                              success={{ percent: 30 }}
                              type="circle"
                              style={{ padding: "1px" }}
                            />
                            <Progress
                              percent={90}
                              success={{ percent: 30 }}
                              type="circle"
                              style={{ padding: "1px" }}
                            />
                          </Tooltip>
                        </Space>
                        {/* Display total orders value here */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
}

export default Homepage;
