import React, { useState, useEffect } from "react";
import axios from "axios";
import "../AdminCss/AdminOrders.css";
import moment from "moment";
import { Select, Modal, Input } from "antd";
import * as mod from "../../utils/url";
import Homepage from "../../components/Homepage";
import { MdPreview, MdDeleteForever } from "react-icons/md";
import { Table } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useAuth } from "../../components/contexts/contextProvider";
import { useOrderContext } from "./../../components/contexts/orderContext";
import ReactPaginate from "react-paginate";
import { FcNext, FcPrevious } from "react-icons/fc";
import { Box, Center } from "@chakra-ui/react";

const userData = JSON.parse(localStorage.getItem("admin")) || {};

const Admin = userData?.admin?.id;
const AdminOrders = ({ status }) => {
  const [orders, setOrders] = useState([]);
  const { auth } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const { Option } = Select;
  const { updateOrderStatus } = useOrderContext();
  const [searchValue, setSearchValue] = useState("");

  const getOrders = async () => {
    try {
      const response = await axios.get(`${mod.api_url}/api/v1/auth/all-orders`);
      if (response.data) {
        setOrders(response.data);
      } else {
        console.error("Orders field is missing in the response data");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      console.log("Setting up interval...");
      getOrders();
    }
  }, [auth?.token]);

  const handlePagination = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handleView = (order) => {
    setSelectedOrder(order);
    setIsPreviewModalVisible(true);
  };

  const handlePrint = () => {
    const printContent = document.getElementById("bill-preview");

    if (printContent) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Print</title></head><body>"
      );
      printWindow.document.write(printContent.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    } else {
      console.error("Element with ID 'bill-preview' not found");
    }
  };

  const handleClosePreviewModal = () => {
    setIsPreviewModalVisible(false);
  };

  const handleChange = async (id, statusData) => {
    try {
      const { data } = await axios.put(
        `${mod.api_url}/api/v1/auth/orders-status/${id}/status`,
        { status: statusData }
      );

      const updatedOrders = orders.map((order) =>
        order._id === id ? { ...order, status: statusData } : order
      );
      setOrders(updatedOrders);
      console.log(data);
    } catch (error) {
      console.log("Error on status update API:", error);
    }
  };

  const handleDeleteOrder = async (id) => {
    try {
      confirmAlert({
        title: "Confirm Deletion",
        message: "Are you sure you want to delete this order?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const { data } = await axios.delete(
                `${mod.api_url}/api/v1/auth/deleteOrders/${id}`
              );
              const { message } = data;
              alert(message);
              getOrders();
            },
          },
          {
            label: "No",
            onClick: () => console.log("Deletion canceled"),
          },
        ],
      });
    } catch (error) {
      console.log(error, "error on delete api");
    }
  };

  const lowercaseSearchValue = searchValue ? searchValue.toLowerCase() : "";

  const orderFilter = orders?.filter((order) => {
    const productName = order?.products[0]?.name || "";
    const priceString = String(order?.products[0]?.price);
    const paymentMethod = order?.paymentMethod || "";
    const status = order?.status || "";
    const dateString = moment(order?.createdAt).format(
      "MMMM Do YYYY, h:mm:ss a"
    );

    const lowercaseSearchValue = searchValue.toLowerCase();

    return (
      productName.toLowerCase().includes(lowercaseSearchValue) ||
      priceString.includes(lowercaseSearchValue) ||
      dateString
        .toLowerCase()
        .includes(lowercaseSearchValue.replace(/[^a-zA-Z0-9]/g, " ")) ||
      paymentMethod.toLowerCase().includes(lowercaseSearchValue)
    );
  });

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <>
      <Box width="100%">
        <Homepage />
        <Center>
          <div className="col-md-11" style={{ marginTop: 130 }}>
            <h1 className="text-center">Manage Books Orders</h1>
            <form
              className="d-flex"
              role="search"
              style={{ marginBottom: 10, justifyContent: "center" }}
            >
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search your product & status &name & mobile & city & price"
                aria-label="Search"
                value={searchValue}
                onChange={handleSearchChange}
              />
            </form>
            <div className="table-container" style={{ overflowY: "auto" }}>
              <div className="border shadow mb-3">
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Image</th>
                      <th scope="col">Product</th>
                      <th scope="col">Price</th>
                      <th scope="col">Selling Price</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderFilter
                      ?.slice(
                        (currentPage - 1) * ordersPerPage,
                        currentPage * ordersPerPage
                      )
                      .map((o, i) =>
                        o?.products?.map((p, j) => (
                          <tr key={j}>
                            <td>{i + 1}</td>
                            <td>
                              <img
                                src={p.image}
                                className="card-img-product productImage"
                                alt={p.name}
                              />
                            </td>

                            <td>{p.name.slice(0, 25)}</td>
                            <td>{p.price}</td>
                            <td>{o.totalPrice.toFixed(2)}</td>

                            <td>
                              {o?.createdAt
                                ? moment(o.createdAt).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )
                                : "N/A"}
                            </td>
                            <td>{o?.status}</td>
                            <td>{o?.products?.length}</td>
                            <td>
                              <button
                                className="button-75"
                                onClick={() => handleDeleteOrder(o._id)}
                              >
                                {" "}
                                <MdDeleteForever />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                  </tbody>
                </Table>
              </div>
            </div>
            <ReactPaginate
              breakLabel="..."
              nextLabel={<FcNext />}
              onPageChange={(selectedPage) =>
                handlePagination(selectedPage.selected + 1)
              }
              pageCount={Math.ceil(orders?.length / ordersPerPage)}
              previousLabel={<FcPrevious />}
              containerClassName="pagination"
              activeClassName="active"
              nextClassName="next"
              previousClassName="previous"
              breakClassName="break"
              pageClassName="page"
              pageLinkClassName="page-link"
              nextLinkClassName="next-link"
              previousLinkClassName="previous-link"
            />
          </div>
        </Center>
      </Box>
    </>
  );
};

export default AdminOrders;
